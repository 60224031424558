import React from 'react'
import styled from 'styled-components'

const StyledTabCard = styled.div`
  height: 250px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  @media screen and (min-width: 900px) {
    height: 325px;
  }
  & input[type="radio"]:checked+label {
    border: solid 5px var(--pink);

    hr {
      background-color: var(--pink);
    }
  }
`
const StyledBreak = styled.hr`
  height: 6px;
  background-color: #000;
`

const StyledCardRadio = styled.input`
  appearance: none;
  display: none;
`
const StyledLabel = styled.label`
  width: 100%;
  height: 100%;
  display: block;
`

const StyledInnerLabel = styled.div`
  padding: 20px;
  height: 100%;
`

const TabCard = ({ monthly, amount, text, fn, pauseFn }) => {

  let type
  if (monthly) {
    type = 'mod'
  } else {
    type = 'ofd'
  }

  return (
    <StyledTabCard
      onClick={() => {
        fn(amount)
        pauseFn(amount)
        document.getElementById('amount').value=amount
        console.log(document.getElementById('amount').value)
      }}>
      <StyledCardRadio
        name={type+'-amount'}
        id={type+'-'+amount}
        type="radio"
        value={amount}
      />
      <StyledLabel
        htmlFor={type+'-'+amount}>
        <StyledInnerLabel>
          <h2>
            £{amount}
          </h2>
          {monthly ? 'Per Month' : ''}
          <StyledBreak/>
          {text}
        </StyledInnerLabel>
      </StyledLabel>

    </StyledTabCard>
  )
}

export default TabCard