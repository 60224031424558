import React from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import styled from 'styled-components'

const SiteGridLayout = styled.div`
  border-top: 5px solid var(--blue);
  --wrapper-gutter: 0;
  --wrapper-max-width: 100%;
  display: grid;
  grid-auto-rows: minmax(0, auto);
  grid-template-columns: minmax(var(--wrapper-gutter),1fr) minmax(0,var(--wrapper-max-width)) minmax(var(--wrapper-gutter),1fr);

  @media screen and (min-width: 900px) {
    --wrapper-gutter: 5vw;
    --wrapper-max-width: 800px;
  }
  @media screen and (min-width: 1231px) {
    --wrapper-max-width: 1200px;
  }
`

const LayoutDefault = ({ children }) => (
  <SiteGridLayout>
    <Header />
    {children}
    <Footer />
  </SiteGridLayout>
)
export default LayoutDefault