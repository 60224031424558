import gql from 'graphql-tag'

const EMOTIVE_QUERY = gql`
query Donationpageemotives {
  donationpageemotives {
    image {
      url
    }
    text,
    link
  }
}
`

export default EMOTIVE_QUERY