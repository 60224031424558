import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Logo from './partials/Logo'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'

const StyledHeader = styled.header`
  --header-height: 92px;
  height: var(--header-height);
  grid-column: span 2;
  padding: 0px 20px 30px 20px;
  @media screen and (min-width: 600px) {
    --header-height: 200px;
    padding: 0px 20px 30px 20px;
  }
  @media screen and (min-width: 900px) {
    grid-column: 2;
}
`
const StyledHeaderInner = styled.div`
`
const StyledNavLink = styled.a`
  padding: 10px 17px;
`
const StyledNavUl = styled.ul`
  display: none;
  @media screen and (min-width: 1231px) {
    display: flex;
  }
`
const StyledNav = styled.nav`
  float: right;
  margin-top: 120px;
`
const StyledButton = styled(Button)`
  float: right;
  margin-top: 35px !important;
  box-shadow: none !important;
  background-color: var(--blue) !important;
  color: #fff !important;
  text-transform: None !important;
  @media screen and (min-width: 600px) {
    margin-top: 105px !important;
  }
  @media screen and (min-width: 1231px) {
    display: none !important;
  }
`

const Header = () => {

  const [isActive, setIsactive] = useState(false)

  const nav = useRef(null)
  const hamburger = useRef(null)

  useEffect(() => {
    isActive && openMenu()
    document.addEventListener('keydown', keyPress)
    document.addEventListener('click', clickOutside)
    return () => {
      document.removeEventListener('keydown', keyPress)
      document.addEventListener('click', clickOutside)
      closeMenu()
    }
  })

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active')
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px'
    setIsactive(true)
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active')
    nav.current && (nav.current.style.maxHeight = null)
    setIsactive(false)
  }

  const keyPress = (e) => { //escape key
    isActive && e.keyCode === 27 && closeMenu()
  }

  const clickOutside = (e) => { //click out of mobile menu
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return
    closeMenu()
  }

  return (
    <StyledHeader>
      <StyledHeaderInner>
        <Logo />
        <StyledButton
          variant="contained"
          startIcon={<MenuIcon />}
        >Menu</StyledButton>
        <StyledNav
          ref={nav}
        >
          <StyledNavUl>
            <li>
              <StyledNavLink>Home</StyledNavLink>
            </li>
            <li>
              <StyledNavLink>About us</StyledNavLink>
            </li>
            <li>
              <StyledNavLink>How you can help</StyledNavLink>
            </li>
            <li>
              <StyledNavLink>Our research</StyledNavLink>
            </li>
            <li>
              <StyledNavLink>About eye diseases</StyledNavLink>
            </li>
          </StyledNavUl>
        </StyledNav>
      </StyledHeaderInner>
    </StyledHeader>
  )
}

export default Header