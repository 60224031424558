import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const centerGridContainer = styled.section`
  grid-column: span 2;

  @media screen and (min-width: 900px) {
    grid-column: 2;
  }
`
export const GlobalStyles = createGlobalStyle`
:root {
  --blue: #025fad;
  --dark-blue: #004b89;
  --pink: #e60f96;
  --grey: #e3e2e3;
  --light-grey: #f0f0ef;
  --color-text: #030303;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    list-style: none;
}
html {
box-sizing: border-box;
margin-block-start: 0px;
}
*, *:before, *:after {
box-sizing: inherit;
}
html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 320px) / 880));
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

h1 {
  font-size: 2.0736rem;
}

h1 {
  font-size: 24.8832px;
}

@media screen and (min-width: 320px) {
  h1 {
    font-size: calc(24.8832px + 35.8668 * ((100vw - 320px) / 880));
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 60.75px;
  }
}

h2 {
  font-size: 1.728rem;
}

h2 {
  font-size: 20.736px;
}

@media screen and (min-width: 320px) {
  h2 {
    font-size: calc(20.736px + 19.764 * ((100vw - 320px) / 880));
  }
}

@media screen and (min-width: 1200px) {
  h2 {
    font-size: 40.5px;
  }
}

h3 {
  font-size: 1.44rem;
}

h3 {
  font-size: 17.28px;
}

@media screen and (min-width: 320px) {
  h3 {
    font-size: calc(17.28px + 9.72 * ((100vw - 320px) / 880));
  }
}

@media screen and (min-width: 1200px) {
  h3 {
    font-size: 27px;
  }
}

body {
  font-family: "Raleway", sans-serif;
  color: var(--color-text);
  font-weight: 300;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  color: var(--color-text);
  line-height: 1.2;
}

h1, h2, h3, h4 {
  color: var(--color-text);
  font-weight: 700;
  margin-bottom: 0.83rem;
}
`