import React from 'react'
import Image from '../elements/Image'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import Button from '../elements/Button'

import * as url from '../../assets/images/emotiveExampleFull.jpg'

const ApiBase = 'http://localhost:1337'


const Markdown = styled(ReactMarkdown)`
  p:last-child {
    margin-bottom: 50px;
 }
`

const EmotiveWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
  & > div {
    flex-basis: 50%;
    min-width: 320px;
    flex-grow: 1;
  }
  &:nth-of-type(2n) {
    flex-flow: row-reverse;
  }
`
const EmotiveTextWrapper = styled.div`
  background-color: var(--grey);
  padding: 30px 40px;

  @media screen and (min-width: 640px) {
    padding: 60px 40px;
  }
`

const Emotive = ({ emotive }) => {
  return (
    <EmotiveWrapper>
      <div>
        <Image src={url} height={'100%'} width={'100%'}/>
      </div>
      <EmotiveTextWrapper>
        <div>
          <Markdown source={'## Appeal Cause\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. '}/>
          <Button text={'Read More'} link={'www.google.com'}/>
        </div>
      </EmotiveTextWrapper>
    </EmotiveWrapper>
  )
}

export default Emotive