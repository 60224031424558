import React from 'react'
import styled from 'styled-components'
//⟵
const StyledFormButton = styled.button`
  color: #fff;
  ${props => props.formNav === true ? 'width: 150px;' : 'width: 100%;' }
  padding: 19px;
  background-color: var(--pink);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
  position: relative;
  transition: 0.5s;
  border: none;

  &:after {
    ${props => props.back === true ? 'content: "⟵";' : 'content: "⟶";'}
    position: absolute;
    opacity: 0;  
    top: calc(50% - 12.5px);
    ${props => props.back === true ? 'right: -20px;' : 'left: -20px;'}
    transition: 0.5s;
  }
  &:hover {
    ${props => props.back === true ? 'padding-right: 34px;' : 'padding-left: 34px;'}
    ${props => props.back === true ? 'padding-left: 4px;' : 'padding-right: 4px;'}
  }
  &:hover:after {
    opacity: 1;
    ${props => props.back === true ? 'right: 10px;' : 'left: 10px;'}
  }
`

const FormButton = ({ subFn, clickFn, text, formNav, back, prev }) => {
  return (
    <StyledFormButton
      onSubmit={subFn}
      onClick={clickFn}
      formNav={formNav}
      back={back}
      type={ prev ? 'button' : 'submit'}>
      {text}
    </StyledFormButton>
  )
}

export default FormButton