import React from 'react'
import styled from 'styled-components'
import Image from '../elements/Image'

import * as accred1 from '../../assets/images/amrc.png'
import * as accred2 from '../../assets/images/fr.webp'

const StyledFooter = styled.footer`
  padding-top: 45px;
  grid-column: 1/-1;
  background-color: var(--blue);
  display: grid;
  grid-auto-rows: minmax(0, auto);
  grid-template-columns: minmax(var(--wrapper-gutter),1fr) minmax(0,var(--wrapper-max-width)) minmax(var(--wrapper-gutter),1fr);
  & * {
    color: #fff;
  }
`
const FooterContainer = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    margin-bottom: 17px;
  }
`
const Accreds = styled.div`
  display: flex;
  flex-wrap: wrap;

`

const AccredImageWrapper = styled.div`
  margin: 5px 10px;
`

const FooterLi = styled.li`
  display: inline-block;
  padding: 0 10px 0 11px;
  border-left: 1px solid #fff;
`

const FooterUl = styled.ul`
  margin: 50px 0px 17px 0px;
  & li:first-child {
    border: none;
  }
`

const Footer = () => {

  return (
    <StyledFooter>
      <FooterContainer>
        <h2 style={{ marginBottom: '17px' }}>Connect with us</h2>
        <Accreds>
          <AccredImageWrapper>
            <Image
              src={accred1}
              width={105}
              height={153}
            />
          </AccredImageWrapper>
          <AccredImageWrapper>
            <Image
              src={accred2}
            />
          </AccredImageWrapper>
        </Accreds>
        <FooterUl>
          <FooterLi>
            <a>Sitemap</a>
          </FooterLi>
          <FooterLi>
            <a>Contact Us</a>
          </FooterLi>
          <FooterLi>
            <a>Accessibility</a>
          </FooterLi>
          <FooterLi>
            <a>Terms & Conditions</a>
          </FooterLi>
          <FooterLi>
            <a>Log in</a>
          </FooterLi>
        </FooterUl>
        <p>The National Eye Research Centre is a Charitable Incorporated Organisation registered with the Charity Commission. Registered Charity Number 1156134</p>
        <p>Registered office: Redwood House, Brotherswood Court, Almondsbury Business Park, Bristol BS32 4QW</p>
        <p>T: <a href="tel:0117%20325 7757">0117 325 7757</a>&nbsp; &nbsp; &nbsp;E: <a href="mailto:hello@nercuk.org">hello@nercuk.org</a></p>
      </FooterContainer>
    </StyledFooter>
  )
}

export default Footer