import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import TabCard from '../components/elements/TabCard'
import FormButton from '../components/elements/FormButton'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const FormStep = ({ children }) => children

const FormWizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = values => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = values => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }
    if (isLastStep) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {formik => (
        <Form>
          <p>
            Step {stepNumber + 1} of {totalSteps}
          </p>
          {step}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {stepNumber > 0 && (
              <FormButton back={true} text={'back'} formNav={true} clickFn={() => previous(formik.values)} prev={true} type="button" />
            )}
            <div>
              {stepNumber === 0 ?
                <></>:
                <FormButton back={false} text={isLastStep ? 'Submit' : 'Next'} formNav={true} clickFn={() => console.log(formik.values.amount)} disabled={formik.isSubmitting} type="submit" />
              }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const StyledTablist = styled.div`
  display: flex;
  & > div {
    display: flex;
    margin-left: 1rem;
  }
`
const StyledTab = styled.input`
  appearance: none;
`
const StyledTabLabel = styled.label`
  padding: 1.6rem 1.6rem 2.4rem;
  background-color: var(--grey);
  cursor: pointer;
  font-weight: 700;

  &[aria-selected="true"] {
    background-color: var(--blue);
    color: #fff;
  }
`
const Tab = styled.div`
  width: 100%;
  background-color: var(--blue);
`
const TabGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(245px,1fr));
  grid-gap: 35px;
  padding: 3.5rem;
`

const TabItem = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 900px) {
    height: 325px;
  }
`
const AmountLabel = styled.label`
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
`
const StyledAmountInput = styled(Field)`
  height: 61px;
  padding: 1rem 1rem 1rem 2rem;
  font-size: 1.4rem;
  width: 100%;
`
const AmountInputPrefix = styled.span`
  top: 14px;
  position: absolute;
  left: 13px;
  font-size: 1.4rem;
`
const AmountInputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`
const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`
const CheckboxLabelStyled = styled.label`
  margin-left: 10px
`
const FormStepTitle = styled.h3`
  font-weight: 700;
`
const FormInfoText = styled.p`
  font-weight: 700;
  margin-bottom: 15px;
`
const FieldLabel = styled.label`
  margin-right: 20px;
  font-size: 16px;
  width: 150px;
`
const FieldInputWrap = styled.div`
  display: flex;
  margin: 15px 0px;
  align-items: center;
`

const FormSectionWrap = styled.div`
  padding: 40px;
  background-color: var(--light-grey);
  margin-bottom: 30px;
`

const WhiteOutlineField = styled(Field)`
  background: #fff !important;
  width: 100%;
`
const DonationForm = ( { pauseCallback } ) => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentTab, setPaymentTab] = useState('one-off')
  const [amount, setAmount] = useState(0)
  const [giftAidEnabled, setGiftAidEnabled] = useState(false)

  const handleChange = (amount) => {
    setAmount(amount)
  }
  const amountChange = (event) => {
    setAmount(event.target.value)
  }
  const handlePayMethod = (method) => {
    setPaymentMethod(method)
  }
  const toggleGiftAid = () => {
    setGiftAidEnabled(!giftAidEnabled)
  }

  let tab

  if (paymentTab === 'monthly') {
    tab =
      <>
        <TabCard
          fn={handleChange}
          pauseFn={pauseCallback}
          monthly={true}
          amount={5}
          text={'£5 per month stops the clock for 5 minutes.'}/>
        <TabCard
          fn={handleChange}
          pauseFn={pauseCallback}
          monthly={true}
          amount={20}
          text={'£20 per month stops the clock for 20 minutes.'} />
        <TabCard
          fn={handleChange}
          pauseFn={pauseCallback}
          monthly={true}
          amount={67}
          text={'£67 per month stops the clock for 60 minutes.'} />
      </>
  } else {
    tab =
    <>
      <TabCard
        fn={handleChange}
        pauseFn={pauseCallback}
        monthly={false}
        amount={10}
        text={'£10 will stop the clock for 10 minutes.'}/>
      <TabCard
        fn={handleChange}
        pauseFn={pauseCallback}
        monthly={false}
        amount={30}
        text={'£30 will stop the clock for 15 minutes.'} />
      <TabCard
        fn={handleChange}
        pauseFn={pauseCallback}
        monthly={false}
        amount={100}
        text={'£100 will stop the clock for £30 minutes.'} />
    </>
  }

  let DonateButtons

  if (paymentTab === 'monthly') {
    DonateButtons =
      <>
        <FormButton
          formNav={false}
          text={'Set-up Direct Debit'}
        />
      </>
  } else {
    DonateButtons =
      <>
        <FormButton
          back={false}
          formNav={false}
          text={'Donate by Debit Card'}
        />
        <FormButton
          back={false}
          formNav={false}
          text={'Donate by PayPal'}
        />
      </>
  }

  return (
    <FormWizard
      initialValues={{
        amount: 0,
        giftAid: false,
        ownMoney: false,
        receivingAnything: false,
        sweepstake: false,
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        postcode: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        townCity: '',
        kitEmail: false,
        kitText: false,
        kitPost: false,
        kitPhone: false
      }}
      onSubmit={async values =>
        sleep(300).then(() => console.log('Wizard submit', values))
      }
    >
      <FormStep
        onSubmit={() => console.log('Step1 onSubmit')}
        validationSchema={Yup.object({
          amount: Yup.number().required('required'),
        })}
      >
        <div>
          <StyledTablist role='tablist'>
            <div>
              <StyledTab
                onClick={() => setPaymentTab('monthly')}
                id='edit-donation-type-m'
                name='donation-type'
                role='tab' type='radio'
              />
              <StyledTabLabel
                aria-selected={paymentTab === 'monthly' ? 'true' : 'false'}
                htmlFor='edit-donation-type-m'
              >
                Monthly donation
              </StyledTabLabel>
            </div>
            <div>
              <StyledTab
                onClick={() => setPaymentTab('one-off')}
                id='edit-donation-type-oo'
                name='donation-type'
                role='tab'
                type='radio'
                defaultChecked
              />
              <StyledTabLabel
                aria-selected={paymentTab === 'one-off' ? 'true' : 'false'}
                htmlFor='edit-donation-type-oo'
              >
                  One-off donation
              </StyledTabLabel>
            </div>
          </StyledTablist>
          <Tab>
            <TabGrid>
              {tab}
              <TabItem>
                <AmountLabel htmlFor='amount'>Other amount</AmountLabel>
                <AmountInputWrapper>
                  <AmountInputPrefix>£</AmountInputPrefix>
                  <StyledAmountInput
                    autoComplete='off'
                    component='input'
                    type='number'
                    step='0.01'
                    min='0.01'
                    placeholder='Amount'
                    id='amount'
                    name='amount'
                    value={amount}
                    onChange={amountChange}
                  />
                </AmountInputWrapper>
                <ErrorMessage className="error" component="div" name="amount" />
                {DonateButtons}
              </TabItem>
            </TabGrid>
          </Tab>
        </div>
      </FormStep>
      <FormStep
        onSubmit={() => console.log('Step2 onSubmit')}
        validationSchema={Yup.object({
          giftAid: Yup.boolean(),
          ownMoney: Yup.boolean().when('giftAid', {
            is: true,
            then: Yup.boolean().oneOf([true], 'Field must be checked'),
          }),
          receivingAnything: Yup.boolean().when('giftAid', {
            is: true,
            then: Yup.boolean().oneOf([true], 'Field must be checked')
          }),
          sweepstake: Yup.boolean().when('giftAid', {
            is: true,
            then: Yup.boolean().oneOf([true], 'Field must be checked')
          })
        })}
      >
        <FormSectionWrap>
          <FormStepTitle>Gift Aid</FormStepTitle>
          <FormInfoText>If you are a UK taxpayer your donation could be worth an additional £{amount*0.25} at no extra cost to you.</FormInfoText>
          <CheckboxWrapper>
            <Field component={Checkbox} onClick={toggleGiftAid} type="checkbox" id='giftAid' name='giftAid'/>
            <CheckboxLabelStyled htmlFor="giftAid">Yes - I am a UK taxpayer and would like to Gift Aid my donations now and in future. I understand I must pay enough income tax and/or capital gains tax each tax year to cover the amount of Gift Aid that all charities and community amateur sports clubs claim on my donations in that tax year, and I am responsible for paying any difference.</CheckboxLabelStyled>
          </CheckboxWrapper>
          <FormInfoText>Please read and confirm the following statements:</FormInfoText>
          <CheckboxWrapper>
            <Field disabled={!giftAidEnabled} component={Checkbox} type="checkbox" id='ownMoney' name='ownMoney' />
            <CheckboxLabelStyled htmlFor="ownMoney">This is my own money. I am not paying in donations made by a third party, e.g. money collected at an event, a funeral collection, the pub, a company donation or a donation from a friend or family membe</CheckboxLabelStyled>
            <ErrorMessage className="error" component="div" name="ownMoney" />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Field disabled={!giftAidEnabled} component={Checkbox} type="checkbox" id='receivingAnything' name='receivingAnything' />
            <CheckboxLabelStyled htmlFor="receivingAnything">I am not receiving anything in return for my donation, e.g. item, auction prize, ticket to an event.</CheckboxLabelStyled>
            <ErrorMessage className="error" component="div" name="receivingAnything" />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Field disabled={!giftAidEnabled} component={Checkbox} type="checkbox" id='sweepstake' name='sweepstake' />
            <CheckboxLabelStyled htmlFor="sweepstake">I am not making a donation as part of a sweepstake, raffle or lotter</CheckboxLabelStyled>
            <ErrorMessage className="error" component="div" name="sweepstake" />
          </CheckboxWrapper>
        </FormSectionWrap>
      </FormStep>
      <FormStep
        onSubmit={() => console.log('Step3 onSubmit')}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          email: Yup.string().email('Invalid email address').required('required')
        })}
      >
        <div>
          <FormStepTitle>Your Details </FormStepTitle>
          <FormSectionWrap>
            <FormStepTitle>Name</FormStepTitle>
            <FieldInputWrap>
              <FieldLabel htmlFor="title">Title *</FieldLabel>
              <Field component={Select} as='select' id='title' name='title' required={true}>
                <MenuItem value='Ms'>Ms</MenuItem>
                <MenuItem value='Mr'>Mr</MenuItem>
                <MenuItem value='Mrs'>Mrs</MenuItem>
                <MenuItem value='Miss'>Miss</MenuItem>
                <MenuItem value='Dr'>Dr</MenuItem>
                <MenuItem value='Professor'>Professor</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
              </Field>
              <ErrorMessage className="error" component="div" name="title" />
            </FieldInputWrap>
            <FieldInputWrap>
              <FieldLabel htmlFor="title">First Name *</FieldLabel>
              <WhiteOutlineField
                autoComplete="First Name"
                component={TextField}
                id="firstName"
                name="firstName"
                placeholder="First Name"
                type="text"
                required={true}
                variant="outlined"
              />
            </FieldInputWrap>
            <FieldInputWrap>
              <FieldLabel htmlFor="title">Last Name *</FieldLabel>
              <WhiteOutlineField
                autoComplete="Last Name"
                component={TextField}
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                type="text"
                required={true}
                variant="outlined"
              />
            </FieldInputWrap>
          </FormSectionWrap>
          <FormSectionWrap>
            <FormStepTitle>Contact</FormStepTitle>
            <FieldInputWrap>
              <FieldLabel htmlFor="email">Email *</FieldLabel>
              <WhiteOutlineField
                autoComplete="email"
                component={TextField}
                id="email"
                name="email"
                placeholder="Email"
                type="text"
                required={true}
                variant="outlined"
              />
              <ErrorMessage className="error" component="div" name="email" />
            </FieldInputWrap>
            <FieldInputWrap>
              <FieldLabel htmlFor="phone">Telephone</FieldLabel>
              <WhiteOutlineField
                autoComplete="phone"
                component={TextField}
                type="tel"
                id="phone"
                name="phone"
                placeholder="Telephone"
                variant="outlined"
              />
              <ErrorMessage className="error" component="div" name="phone" />
            </FieldInputWrap>
          </FormSectionWrap>
          <div>
            <FormSectionWrap>
              <FormStepTitle>Address</FormStepTitle>
              <FieldInputWrap>
                <FieldLabel htmlFor="addressLine1">Address line 1 *</FieldLabel>
                <WhiteOutlineField
                  autoComplete="address"
                  component={TextField}
                  id="addressLine1"
                  name="addressLine1"
                  placeholder="Address line 1"
                  type="text"
                  required={true}
                  variant="outlined"
                />
                <ErrorMessage className="error" component="div" name="addressLine1" />
              </FieldInputWrap>
              <FieldInputWrap>
                <FieldLabel htmlFor="addressLine2">Address line 2</FieldLabel>
                <WhiteOutlineField
                  autoComplete="address"
                  component={TextField}
                  id="addressLine2"
                  name="addressLine2"
                  placeholder="Address line 2"
                  type="text"
                  variant="outlined"
                />
                <ErrorMessage className="error" component="div" name="addressLine2" />
              </FieldInputWrap>
              <FieldInputWrap>
                <FieldLabel htmlFor="addressLine3">Address line 3</FieldLabel>
                <WhiteOutlineField
                  autoComplete="address"
                  component={TextField}
                  id="addressLine3"
                  name="addressLine3"
                  placeholder="Address line 3"
                  type="text"
                  variant="outlined"
                />
                <ErrorMessage className="error" component="div" name="addressLine3" />
              </FieldInputWrap>
              <FieldInputWrap>
                <FieldLabel htmlFor="postcode">Postcode *</FieldLabel>
                <WhiteOutlineField
                  autoComplete="postcode"
                  component={TextField}
                  id="postcode"
                  name="postcode"
                  placeholder="Postcode"
                  type="text"
                  required={true}
                  variant="outlined"
                />
                <ErrorMessage className="error" component="div" name="townCity" />
              </FieldInputWrap>
              <FieldInputWrap>
                <FieldLabel htmlFor="townCity">Town or city *</FieldLabel>
                <WhiteOutlineField
                  autoComplete="address"
                  component={TextField}
                  id="townCity"
                  name="townCity"
                  placeholder="Town or city"
                  type="text"
                  required={true}
                  variant="outlined"
                />
                <ErrorMessage className="error" component="div" name="townCity" />
              </FieldInputWrap>
              <FieldInputWrap>
                <FieldLabel htmlFor="title">Country *</FieldLabel>
                <Field component={Select} as='select' id='country' name='country' required={true}>
                  <option value="">Please select
                  </option><option value="United Kingdom">United Kingdom
                  </option><option value="Afghanistan">Afghanistan
                  </option><option value="Aland Islands">Aland Islands
                  </option><option value="Albania">Albania
                  </option><option value="Algeria">Algeria
                  </option><option value="American Samoa">American Samoa
                  </option><option value="Andorra">Andorra
                  </option><option value="Angola">Angola
                  </option><option value="Anguilla">Anguilla
                  </option><option value="Antarctica">Antarctica
                  </option><option value="Antigua and Barbuda">Antigua and Barbuda
                  </option><option value="Argentina">Argentina
                  </option><option value="Armenia">Armenia
                  </option><option value="Aruba">Aruba
                  </option><option value="Australia">Australia
                  </option><option value="Austria">Austria
                  </option><option value="Azerbaijan">Azerbaijan
                  </option><option value="Bahamas">Bahamas
                  </option><option value="Bahrain">Bahrain
                  </option><option value="Bangladesh">Bangladesh
                  </option><option value="Barbados">Barbados
                  </option><option value="Belarus">Belarus
                  </option><option value="Belgium">Belgium
                  </option><option value="Belize">Belize
                  </option><option value="Benin">Benin
                  </option><option value="Bermuda">Bermuda
                  </option><option value="Bhutan">Bhutan
                  </option><option value="Bolivia">Bolivia
                  </option><option value="Bosnia and Herzegovina">Bosnia and Herzegovina
                  </option><option value="Botswana">Botswana
                  </option><option value="Bouvet Island">Bouvet Island
                  </option><option value="Brazil">Brazil
                  </option><option value="British Indian Ocean Territory">British Indian Ocean Territory
                  </option><option value="Brunei">Brunei
                  </option><option value="Bulgaria">Bulgaria
                  </option><option value="Burkina Faso">Burkina Faso
                  </option><option value="Burundi">Burundi
                  </option><option value="Cambodia">Cambodia
                  </option><option value="Cameroon">Cameroon
                  </option><option value="Canada">Canada
                  </option><option value="Cape Verde">Cape Verde
                  </option><option value="Cayman Islands">Cayman Islands
                  </option><option value="Central African Republic">Central African Republic
                  </option><option value="Chad">Chad
                  </option><option value="Chile">Chile
                  </option><option value="China">China
                  </option><option value="Christmas Island">Christmas Island
                  </option><option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands
                  </option><option value="Colombia">Colombia
                  </option><option value="Comoros">Comoros
                  </option><option value="Congo">Congo
                  </option><option value="Cook Islands">Cook Islands
                  </option><option value="Costa Rica">Costa Rica
                  </option><option value="Cote d'Ivoire">Cote d'Ivoire
                  </option><option value="Croatia">Croatia
                  </option><option value="Cuba">Cuba
                  </option><option value="Cyprus">Cyprus
                  </option><option value="Czech Republic">Czech Republic
                  </option><option value="Democratic Republic of the Congo">Democratic Republic of the Congo
                  </option><option value="Denmark">Denmark
                  </option><option value="Djibouti">Djibouti
                  </option><option value="Dominica">Dominica
                  </option><option value="Dominican Republic">Dominican Republic
                  </option><option value="East Timor">East Timor
                  </option><option value="Ecuador">Ecuador
                  </option><option value="Egypt">Egypt
                  </option><option value="El Salvador">El Salvador
                  </option><option value="Equatorial Guinea">Equatorial Guinea
                  </option><option value="Eritrea">Eritrea
                  </option><option value="Estonia">Estonia
                  </option><option value="Ethiopia">Ethiopia
                  </option><option value="Falkland Islands">Falkland Islands
                  </option><option value="Faroe Islands">Faroe Islands
                  </option><option value="Fiji">Fiji
                  </option><option value="Finland">Finland
                  </option><option value="France">France
                  </option><option value="French Guiana">French Guiana
                  </option><option value="French Polynesia">French Polynesia
                  </option><option value="French Southern Territories">French Southern Territories
                  </option><option value="Gabon">Gabon
                  </option><option value="Gambia">Gambia
                  </option><option value="Georgia">Georgia
                  </option><option value="Germany">Germany
                  </option><option value="Ghana">Ghana
                  </option><option value="Gibraltar">Gibraltar
                  </option><option value="Greece">Greece
                  </option><option value="Greenland">Greenland
                  </option><option value="Grenada">Grenada
                  </option><option value="Guadeloupe">Guadeloupe
                  </option><option value="Guam">Guam
                  </option><option value="Guatemala">Guatemala
                  </option><option value="Guernsey">Guernsey
                  </option><option value="Guinea">Guinea
                  </option><option value="Guinea-Bissau">Guinea-Bissau
                  </option><option value="Guyana">Guyana
                  </option><option value="Haiti">Haiti
                  </option><option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands
                  </option><option value="Honduras">Honduras
                  </option><option value="Hong Kong">Hong Kong
                  </option><option value="Hungary">Hungary
                  </option><option value="Iceland">Iceland
                  </option><option value="India">India
                  </option><option value="Indonesia">Indonesia
                  </option><option value="Iran">Iran
                  </option><option value="Iraq">Iraq
                  </option><option value="Ireland">Ireland
                  </option><option value="Isle of Man">Isle of Man
                  </option><option value="Israel">Israel
                  </option><option value="Italy">Italy
                  </option><option value="Jamaica">Jamaica
                  </option><option value="Japan">Japan
                  </option><option value="Jersey">Jersey
                  </option><option value="Jordan">Jordan
                  </option><option value="Kazakhstan">Kazakhstan
                  </option><option value="Kenya">Kenya
                  </option><option value="Kiribati">Kiribati
                  </option><option value="Kuwait">Kuwait
                  </option><option value="Kyrgyzstan">Kyrgyzstan
                  </option><option value="Lao People's Democratic Republic">Lao People's Democratic Republic
                  </option><option value="Latvia">Latvia
                  </option><option value="Lebanon">Lebanon
                  </option><option value="Lesotho">Lesotho
                  </option><option value="Liberia">Liberia
                  </option><option value="Libya">Libya
                  </option><option value="Liechtenstein">Liechtenstein
                  </option><option value="Lithuania">Lithuania
                  </option><option value="Luxembourg">Luxembourg
                  </option><option value="Macao">Macao
                  </option><option value="Macedonia, The former Yugoslav Republic of">Macedonia, The former Yugoslav Republic of
                  </option><option value="Madagascar">Madagascar
                  </option><option value="Malawi">Malawi
                  </option><option value="Malaysia">Malaysia
                  </option><option value="Maldives">Maldives
                  </option><option value="Mali">Mali
                  </option><option value="Malta">Malta
                  </option><option value="Marshall Islands">Marshall Islands
                  </option><option value="Martinique">Martinique
                  </option><option value="Mauritania">Mauritania
                  </option><option value="Mauritius">Mauritius
                  </option><option value="Mayotte">Mayotte
                  </option><option value="Mexico">Mexico
                  </option><option value="Micronesia">Micronesia
                  </option><option value="Moldova">Moldova
                  </option><option value="Monaco">Monaco
                  </option><option value="Mongolia">Mongolia
                  </option><option value="Montenegro">Montenegro
                  </option><option value="Montserrat">Montserrat
                  </option><option value="Morocco">Morocco
                  </option><option value="Mozambique">Mozambique
                  </option><option value="Myanmar">Myanmar
                  </option><option value="Namibia">Namibia
                  </option><option value="Nauru">Nauru
                  </option><option value="Nepal">Nepal
                  </option><option value="Netherlands">Netherlands
                  </option><option value="Netherlands Antilles">Netherlands Antilles
                  </option><option value="New Caledonia">New Caledonia
                  </option><option value="New Zealand">New Zealand
                  </option><option value="Nicaragua">Nicaragua
                  </option><option value="Niger">Niger
                  </option><option value="Nigeria">Nigeria
                  </option><option value="Niue">Niue
                  </option><option value="Norfolk Island">Norfolk Island
                  </option><option value="Northern Marianas">Northern Marianas
                  </option><option value="North Korea">North Korea
                  </option><option value="Norway">Norway
                  </option><option value="Oman">Oman
                  </option><option value="Pakistan">Pakistan
                  </option><option value="Palau">Palau
                  </option><option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied
                  </option><option value="Panama">Panama
                  </option><option value="Papua New Guinea">Papua New Guinea
                  </option><option value="Paraguay">Paraguay
                  </option><option value="Peru">Peru
                  </option><option value="Philippines">Philippines
                  </option><option value="Pitcairn Islands">Pitcairn Islands
                  </option><option value="Poland">Poland
                  </option><option value="Portugal">Portugal
                  </option><option value="Puerto Rico">Puerto Rico
                  </option><option value="Qatar">Qatar
                  </option><option value="Reunion">Reunion
                  </option><option value="Romania">Romania
                  </option><option value="Russia">Russia
                  </option><option value="Rwanda">Rwanda
                  </option><option value="Saint Helena">Saint Helena
                  </option><option value="Saint Kitts and Nevis">Saint Kitts and Nevis
                  </option><option value="Saint Lucia">Saint Lucia
                  </option><option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon
                  </option><option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines
                  </option><option value="Samoa">Samoa
                  </option><option value="San Marino">San Marino
                  </option><option value="Saudi Arabia">Saudi Arabia
                  </option><option value="Senegal">Senegal
                  </option><option value="Serbia">Serbia
                  </option><option value="Seychelles">Seychelles
                  </option><option value="Sierra Leone">Sierra Leone
                  </option><option value="Singapore">Singapore
                  </option><option value="Slovakia">Slovakia
                  </option><option value="Slovenia">Slovenia
                  </option><option value="Soa Tome and Principe">Soa Tome and Principe
                  </option><option value="Solomon Islands">Solomon Islands
                  </option><option value="Somalia">Somalia
                  </option><option value="South Africa">South Africa
                  </option><option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands
                  </option><option value="South Korea">South Korea
                  </option><option value="Spain">Spain
                  </option><option value="Sri Lanka">Sri Lanka
                  </option><option value="Sudan">Sudan
                  </option><option value="Suriname">Suriname
                  </option><option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen
                  </option><option value="Swaziland">Swaziland
                  </option><option value="Sweden">Sweden
                  </option><option value="Switzerland">Switzerland
                  </option><option value="Syria">Syria
                  </option><option value="Taiwan">Taiwan
                  </option><option value="Tajikistan">Tajikistan
                  </option><option value="Tanzania">Tanzania
                  </option><option value="Thailand">Thailand
                  </option><option value="Togo">Togo
                  </option><option value="Tokelau">Tokelau
                  </option><option value="Tonga">Tonga
                  </option><option value="Trinidad and Tobago">Trinidad and Tobago
                  </option><option value="Tunisia">Tunisia
                  </option><option value="Turkey">Turkey
                  </option><option value="Turkmenistan">Turkmenistan
                  </option><option value="Turks and Caicos Islands">Turks and Caicos Islands
                  </option><option value="Tuvalu">Tuvalu
                  </option><option value="Uganda">Uganda
                  </option><option value="Ukraine">Ukraine
                  </option><option value="United Arab Emirates">United Arab Emirates
                  </option><option value="United States of America">United States of America
                  </option><option value="United States Minor Outlying Islands">United States Minor Outlying Islands
                  </option><option value="Uzbekistan">Uzbekistan
                  </option><option value="Vatican City">Vatican City
                  </option><option value="Venezuela">Venezuela
                  </option><option value="Vietnam">Vietnam
                  </option><option value="Wallis and Futuna">Wallis and Futuna
                  </option><option value="Western Sahara">Western Sahara
                  </option><option value="Yemen">Yemen
                  </option><option value="Zambia">Zambia
                  </option><option value="Zimbabwe">Zimbabwe
                  </option>
                </Field>
                <ErrorMessage className="error" component="div" name="country" />
              </FieldInputWrap>
            </FormSectionWrap>
            <FormSectionWrap>
              <FormStepTitle>Let's Stay in Touch!</FormStepTitle>
              <FormInfoText>The world of research never sleeps. Every day scientists are edging closer to a solution for sight loss. We’d love to keep you up to date with all news and breakthroughs from our community.</FormInfoText>
              <FormInfoText>Please let us know how you'd like to hear from us:</FormInfoText>
              <CheckboxWrapper>
                <Field component={Checkbox} type="checkbox" id='kitEmail' name='kitEmail'/>
                <CheckboxLabelStyled htmlFor="kitEmail">Email</CheckboxLabelStyled>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Field component={Checkbox} type="checkbox" id='kitPhone' name='kitPhone'/>
                <CheckboxLabelStyled htmlFor="kitPhone">Phone</CheckboxLabelStyled>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Field component={Checkbox} type="checkbox" id='kitText' name='kitText'/>
                <CheckboxLabelStyled htmlFor="kitText">Text</CheckboxLabelStyled>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Field component={Checkbox} type="checkbox" id='kitPost' name='kitPost'/>
                <CheckboxLabelStyled htmlFor="kitPost">Post</CheckboxLabelStyled>
              </CheckboxWrapper>
              <FormInfoText>We take your privacy seriously and will never sell or swap your details with third parties. You can withdraw your consent to be contacted at any time by calling 0117 325 7757 or emailing hello@nercuk.org</FormInfoText>
              <FormInfoText>Information about how we protect and use your personal data is set out in our privacy policy.</FormInfoText>
            </FormSectionWrap>
          </div>
        </div>
      </FormStep>
    </FormWizard>
  )
}

export default DonationForm