import gql from 'graphql-tag'

const LOGO_QUERY = gql`
query NercLogo {
  nercLogo {
	 logo {
  	url
  	}
  }
}
`

export default LOGO_QUERY