import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.a`
  color: #fff;
  padding: 20px 46px;
  background-color: var(--pink);
  text-decoration: none;
  position: relative;
  transition: 0.5s;

  &:after {
    content: '⟶';
    position: absolute;
    opacity: 0;  
    top: calc(50% - 12.5px);
    left: -20px;
    transition: 0.5s;
  }
  &:hover {
    padding-left: 54px;
    padding-right: 38px;
  }
  &:hover:after {
    opacity: 1;
    left: 10px;
  }
`

const Button = ({ text, link }) => {
  return (
    <StyledButton target='_blank' href={link}>
      {text}
    </StyledButton>
  )
}

export default Button