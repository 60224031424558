import React, { useRef, useEffect } from 'react'

const Image = ({
  src,
  width,
  height,
  alt,
  ...props
}) => {
  const image = useRef(null)

  useEffect(() => {
    const placeholderImage = document.createElement('img')
    handlePlaceholder(image.current, placeholderImage)
  }, [])

  const placeholderSrc = (w, h) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`
  }

  const handlePlaceholder = (img, placeholder) => {
    img.style.display = 'none'
    img.before(placeholder)
    placeholder.src = placeholderSrc(
      img.getAttribute('width') || 0,
      img.getAttribute('height') || 0
    )
    placeholder.width = img.getAttribute('width')
    placeholder.height = img.getAttribute('height')
    placeholder.style.opacity = '0'

    img.addEventListener('load', () => {
      placeholder.remove()
      img.style.display = ''
    })
  }

  return (
    <img
      {...props}
      ref={image}
      src={src}
      width={width}
      height={height}
      alt={alt} />
  )
}

export default Image