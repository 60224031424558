import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { centerGridContainer } from '../styles/styles'
import Emotives from '../components/sections/Emotives'
import DonationForm from '../forms/DonationForm'
import Emotive from '../components/elements/Emotive'

const Content = styled(centerGridContainer)`
  margin-bottom: 3rem;
`
const StyledClock = styled.h1`
  background: var(--blue);
  padding: 20px 80px;
  border-radius: 60px;
  color: #fff;
  margin-bottom: 40px;
  font-family: "Orbitron", sans-serif;
  min-width: 30%;
  text-align: center;
`
const ClockWrapper = styled(Content)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const IntroText = styled.h4`
  width: 100%;
  padding: 0px 20px;
  @media screen and (min-width: 1200px) {
    font-size: 24px;
    padding: 0px 40px;
    line-height: 1.4;
  }
`

const Home = () => {
  const [showEmotives, setShowEmotives] = useState(true)
  const [counter, setCounter] = useState(360)
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(6)
  const [paused, setPaused] = useState(false)
  const [pausedTime, setPausedTime] = useState(0)
  const [time, setTime] = useState(null)

  useEffect(() => {
    counter > -1 && !paused && setTimeout(() => setCounter(counter - 1), 1000)
    if(counter < 1) {
      setTime(<button onClick={clockReset}>Reset</button>)
    } else {
      setMinutes(Math.floor((counter) / 60))
      setSeconds(counter % 60)
      setTime(minutes+':'+str_pad_left(seconds,'0',2))
    }
  }, [counter])

  const clockReset = () => {
    setCounter(360)
  }

  const pauseTimer = (time) => {
    setPaused(true)
    console.log(time)
  }

  function str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length)
  }

  let emotives

  if(showEmotives) {
    emotives = <Emotives />
  } else {
    emotives = null
  }

  return (
    <>
      <ClockWrapper>
        <h2>Stop the Clock</h2>
        <StyledClock>{time}</StyledClock>
        <IntroText>Every 6 minutes someone in the UK finds out they are going blind. That’s 250 people a day.
          The only way to stop this number from rising is to invest in research.
        <br/>
          Your support will give researchers the chance to find treatments and solutions for eye disease before it’s too late.
        <br/>
          Your donation buys time for millions of people in the UK who are minutes away from a diagnosis.
        <br/>
          By stopping the clock, you’ll save someone from losing their sight.
        </IntroText>
      </ClockWrapper>
      <Content>
        <DonationForm pauseCallback={pauseTimer}/>
      </Content>
      <Content>
        <Emotive />
      </Content>
    </>
  )
}

export default Home