import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../elements/Image'
import Query from '../../../components/elements/Query'
import LOGO_QUERY from '../../../queries/logo'
import styled from 'styled-components'

import * as url from '../../../assets/images/logo.webp'

const ApiBase = 'http://localhost:1337'

const LogoImage = styled(Image)`
  --logo-height: 67px;
  height: var(--logo-height);
  margin: 10px 0px;

  @media screen and (min-width: 600px) {
    --logo-height: 136px;
    margin: 25px 0 20px;
  }
`

const Logo = ({ ...props }) => {
  return(
    <Link to='/'>
      {/* <Query query={LOGO_QUERY} id={null}> */}
      {/* {({ data: { nercLogo: { logo: { url } } } }) => { */}
      {/* return ( */}
      <LogoImage
        src={url}
      />
      {/* ) */}
      {/* }} */}
      {/* </Query> */}
    </Link>
  )
}

export default Logo