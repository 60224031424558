import React from 'react'
import { Switch } from 'react-router-dom'
import { Normalize } from 'styled-normalize'
import AppRoute from './utils/AppRoute'


// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views
import DonationPage from './views/DonationPage'

// Styles
import { GlobalStyles } from './styles/styles'
//TODO: Add ga??


const App = () => {

  return (
    <>
      <Normalize />
      <GlobalStyles />
      <Switch>
        <AppRoute exact path='/' component={DonationPage} layout={LayoutDefault} />
      </Switch>
    </>
  )
}

export default App