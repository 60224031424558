import React from 'react'
import Emotive from '../elements/Emotive'
import Query from '../elements/Query'
import EMOTIVE_QUERY from '../../queries/emotive'

const Emotives = () => {
  return(
    <Query query={EMOTIVE_QUERY} id={null}>
      {({ data: { donationpageemotives } }) => {
        return (
          <>
            {donationpageemotives.map((emotive, i) =>
              <Emotive
                key={i}
                emotive={emotive}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}

export default Emotives